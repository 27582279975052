.footer-section {
  background-color: #201d1e;
  color: white;
}

.footer-container-1 {
  color: white;
  padding: 2% 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
}

.list-unstyled {
    list-style: none;
}

.text-muted {
    color: white;
    border: 2px blue solid;
}