.center-title {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.standard-margin {
    padding-left: 10%;
    padding-right: 10%;
}

/*our Product page */
.row {
    margin: 0px;
}
.product-card{
    margin-bottom: 5%;
}
