.gallery {
    position: relative;
    width: 300px;
    height: 200px;
    transform-style: preserve-3d;
    animation: rotate 50s linear infinite;
}

@keyframes rotate {
    from {
        transform: perspective(1200px) rotateY(0deg);
    }

    to {
        transform: perspective(1200px) rotateY(360deg);
    }
}

.gallery span {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-origin: center;
    transform-style: preserve-3d;
    transform: rotateY(calc(var(--i) * 45deg)) translateZ(380px);
}

.gallery span img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}