.about-wallpaper {
    background-color: #F7F7F7;
    align-items: center;
    height: 80vh;
    justify-items: center;
    align-content: center;
    position: static;
    margin-top: 4%;
}
.aboutcard-box-shadow {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.headingCenter {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.VerticalCenter {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding:"2%";
    border:"1px solid black"
}

.managementTeam {
    background-color: #F7F7F7;
}