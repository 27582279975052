/* The following line can be included in a src/App.scss */

$color: #0cf;

.animatedButton {
    display: inline-block;
    padding: .75rem 1.25rem;
    border-radius: 10rem;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.4rem;
    letter-spacing: .15rem;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    text-decoration: none;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color;
        border-radius: 10rem;
        z-index: -2;
    }

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: darken($color, 15%);
        transition: all .3s;
        border-radius: 10rem;
        z-index: -1;
    }

    &:hover {
        color: #fff;

        &:before {
            width: 100%;
        }
    }
}