* {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Underline */

.bottom-shortline-center {
display: flex;
flex-wrap: wrap;
justify-content: center;
}

.bottom-shortline {
  margin-top: 20px;
  margin-bottom: 40px;
  margin-left: 0;
  border-top: 0px;
  height: 5px;
  width: 5%;
  text-align: center;

  background-color: rgb(2, 237, 225);
}

.bottom-line-left {
  margin-top: 20px;
  margin-bottom: 40px;
  border: 0;
  margin-left: 0;
  border-top: 0px;
  height: 3px;
  text-align: center;
  width: 40px;
  background-color: rgb(2, 237, 237);
}

.quality-wallpaper {
  background-color: #F7F7F7;
}
.blur-background {
  filter: blur(3px);
    -webkit-filter: blur(3px);
}
.blur-background-2 {
  height: 100vh;
  -webkit-filter: blur(2px);
}

.textPos{
  text-align: center;
}

.make-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.give-border {
  border: 1px solid black;
}

.avatar {
  width: 100px; /* Set the width of the avatar */
  height: 100px; /* Set the height of the avatar */
  border-radius: 50%; /* Make the border-radius 50% to create a circle */
  overflow: hidden; /* Hide any overflow beyond the circle */
}

.map{
  height: 400px;
}

.carousel-img{
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.bold-title {
  font-weight: 600;
  font-size: 50px;
}
.bold-subtitle {
    font-weight: 600;
      font-size: 30px;
}


.animated-button {
  background-color: #008CBA;
  color: #ffffff;
  font-size: 16px;
  padding: 10px 20px 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 15px 0 #006699;
  width: 350px;
  animation: example 1s infinite;
}

@keyframes example {
  0% {
    background-color: #008CBA;
  }

  50% {
    background-color: #006699;
  }

  100% {
    background-color: #008CBA;
  }
}


.try {
animation-name: fade-zoom;
animation-duration: 15s;
animation-iteration-count: infinite;
}

.bounding-box {
  background-image: url("./asset//Home/WelcomeBackground/wp2.jpg");
  background-size: cover;
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

@keyframes fade-zoom {
  0% {
    opacity: 0;
    transform: scale(100%);
  }

  16.66% {
    opacity: 1;
  }

  48.66% {
    opacity: 0.8;
  }
100% {
  opacity: 1;
  transform: scale(110%);
}
}

.subtitle {
  font-size: 21px;
  font-weight: 500;
}

.content-style {
  font-size: 19px;
}

.about-title {
  font-size: 30px;
  font-weight: 600;
}